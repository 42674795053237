import { graphql, Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import ContactForm from "../components/contactForm/form";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Seo from "../components/seo";
import SlickArrowLeft from "../components/SlickArrowLeft";
import SlickArrowRight from "../components/slickArrowRight";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";
import testimonialBg1 from "../images/CustomerStories/Armondzarookian.svg";
import iconAdv1 from "../images/CustomerStories/icon-adv-1.png";
import iconAdv2 from "../images/CustomerStories/icon-adv-2.png";
import iconAdv3 from "../images/CustomerStories/icon-adv-3.png";
import gradientBg from "../images/OrdersConsolidation/gradient-pink-bg.png";
import YoutubeVideo from './../components/youtubeVideo/youtubeVideo';

// markup
const CustomerStoriesPage = ({ location, data }) => {

  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 2,
    title: <>At Orders.co we treat our customers as our partners.</>,
    hideLine: true,
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="customers"
        src="../images/CustomerStories/customers.png"
        className="mw-100"
      />
    ),
  };

  const testimonials = [
    {
      bgImage: testimonialBg1,
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/CustomerStories/Armondzarookian.png"
          alt="armond zarookian"
          className="mw-100 w-100"
        />
      ),
      text: `I love their 3rd Party Delivery Integration and Commission-Free Website with Flex Delivery.
                I've increased my sales by 33% in the first month alone!`,
      author: "Armond Zarookian",
      position: "Owner of Slash Pizza",
    },
  ];

  const testimonialsProps = {
    layout: 4,
    items: testimonials,
    title: <span className="text-left d-block">Slash Pizza</span>,
    tag: "",
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "Personalized Website",
      description: `Create your very own ordering website in no time with just your menu.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "Direct Ordering",
      description: `Cut out the middlemen and give your customers an easy way to order directly from you without the commission!`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reliable Delivery",
      description: `Offer delivery on all website orders with in-house staff or through our delivery partners.`,
    },
  ];

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Customers situation",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Slash Pizza was founded in 2011 and brings home-style cooking to
        Glendale, CA by offering different types of pizzas, pastas, and
        sandwiches. Slash Pizza was struggling to grow their revenue streams,
        despite offering dine-in, carry-out, and delivery.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="delivery"
        src="../images/CustomerStories/tablet-delivery.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "The Challenge",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        Online ordering is gaining more popularity and for businesses it is
        becoming more challenging to manage all orders from different delivery
        apps. Every app has it’s tablet, and it is hard to keep track of it
        especially during busy hours when each one functions differently.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="online ordering"
        src="../images/CustomerStories/challenge.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-big",
    title: "Orders.co Solution",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Everything now is in one device and you can easily keep track of your
        orders and their statuses. Furthermore, daily reporting is easy and
        simplified for all your orders. Having everything in one place removes
        the headaches of multiple tablets and helps ease the learning process
        for new employees.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="orders.co solution"
        src="../images/CustomerStories/Orders.co-Solution.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "equal-big",
    title: "The Outcome",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        Cooperation with Orders.co made Slash Pizza’s commission-free website
        the top revenue generator in just 3 months. With a total revenue
        increase of 73% across all online ordering. Armond has shifted his
        attention to promoting his website and primarily uses delivery apps for
        new customer acquisition and marketing.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="outcome"
        src="../images/CustomerStories/outcome.png"
      />
    ),
  };

  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 2,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title={"Customer Stories"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Testimonials {...testimonialsProps} />
        <div
          className="bg-desktop pb-0 pb-md-5 mb-0"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
          }}
        >
          <YoutubeVideo
            videoId={"oX6TgspyDuA"}
            image={<StaticImage
              placeholder="none"
              loading="eager"
              alt="youtube-placeholder"
              className="mw-100 w-100 mh-100"
              src="../images/CustomerStories/Armond Zarookian.png"
            />}
          ></YoutubeVideo>
          <Features title="Top Benefits" items={features} />
          <Title>
            <b
              className="mt-5 mt-md-0"
              style={{ fontSize: "42.861px", lineHeight: "64px" }}
            >
              All Your Online Orders in One Device
            </b>
          </Title>
          <ImageText {...imageTextProps1} />
          <ImageText {...imageTextProps2} />
          <ImageText {...imageTextProps3} />
          <ImageText {...imageTextProps4} />
        </div>
        <div
          className="d-none d-xl-block"
          style={{ marginBottom: "183px" }}
        ></div>
        <Title>
          <b
            className="text-center text-lg-left d-block"
            style={{ fontSize: "42.861px", lineHeight: "64px" }}
          >
            From Local Diners to Multinational Chains
          </b>
        </Title>
        <div className="container mb-3 mb-lg-5 pb-3 pb-lg-5">
          <Slider {...sliderSettings}>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="blurred"
                    alt="Independent Restaurants"
                    src="../images/CustomerStories/slider-img-1.png"
                    className="mw-100"
                  />
                </div>

                <Link to="/independent-restaurants/" className="post-title">
                  Independent Restaurants
                </Link>
                <div className="post-description">
                  Orders.co was built from the ground up to support restaurants
                  of varying sizes. Our tools help cut costs, save employee
                  time, and improve workflow. All while being scalable to match
                  your business’s needs.
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="Chain Restaurants"
                    src="../images/CustomerStories/slider-img-2.png"
                    className="mw-100"
                  />
                </div>
                <Link to="/chain-restaurants/" className="post-title">
                  Chain Restaurants
                </Link>
                <div className="post-description">
                  The Orders.co suite was built to facilitate chain restaurants'
                  needs like never before. Executives can review analytics from
                  all connected locations from one unified administrator
                  account. While Store Operators maintain full control over
                  their individual locations.
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div ref={formRef}>
          <ContactForm></ContactForm>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "customer-stories" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default CustomerStoriesPage;
